import { type ActionFunctionArgs } from '@remix-run/node'
import { type LoaderFunctionArgs } from '@remix-run/server-runtime'
import Autoplay from 'embla-carousel-autoplay'
import { useTranslation } from 'react-i18next'
import { json } from 'zod-form-data'

import { Button } from '#app/components/ui/button.tsx'
import { Card, CardContent } from '#app/components/ui/card.tsx'
import {
	Carousel,
	CarouselContent,
	CarouselItem,
} from '#app/components/ui/carousel.tsx'

import { MenuContainer } from '#app/features/menu/container.tsx'
import { useCurrentLanguage } from '#app/hooks/use-current-language.tsx'
import { useCustomNavigate } from '#app/hooks/use-custom-navigate.tsx'
import { getUserId } from '#app/utils/auth.server.ts'
import { mixpanel } from '#app/utils/mixpanel.server.ts'
import { useOptionalUser } from '#app/utils/user.ts'
import { getLanguageIdFromPathname } from '#server/utils/getLanguageIdFromPathname.ts'

const items = [
	{
		title: 'Daily Mindfulness Tips',
		img: '/img/old-lady.jpg',
	},
	{
		title: 'Stress Relief Techniques',
		img: '/img/sitting-man-meditate.jpg',
	},
	{
		title: 'Mood Tracking and Analysis',
		img: '/img/girl-calm.png',
	},

	{
		title: 'Coping Strategies for Anxiety',
		img: '/img/happy-standing-man.jpg',
	},
	{
		title: 'Guided Positive Affirmations',
		img: '/img/forest-family.png',
	},

	{
		title: 'Emotional Wellness Activities',
		img: '/img/woman-blue.png',
	},

	{
		title: 'Mindful Breathing Guides',
		img: '/img/woman-colors.jpg',
	},
	{
		title: 'Self-Esteem Building ',
		img: '/img/woman-smile.png',
	},
	{
		title: 'Sleep Improvement Tips',
		img: '/img/color-woman.jpg',
	},
	{
		title: 'Personal Growth Insights',
		img: '/img/girl-sitting.png',
	},
	{
		title: 'Healthy Habit Formation',
		img: '/img/big-woman.jpg',
	},
	{
		title: 'Anger Management Techniques',
		img: '/img/happy-standing-woman.jpg',
	},
	{
		title: 'Navigating Life Transitions',
		img: '/img/woman-sitting-smile.jpg',
	},
	{
		title: 'Overcoming Procrastination',
		img: '/img/standing-woman-peace.jpg',
	},
	{
		title: 'Building Resilience and Grit',
		img: '/img/one-arm-woman.jpg',
	},
	{
		title: 'Mindful Eating Habits',
		img: '/img/color-woman.jpg',
	},
	{
		title: ' Expression for Emotional Health',
		img: '/img/happy-man.jpg',
	},
]

export async function loader({ request }: LoaderFunctionArgs) {
	const languageId = getLanguageIdFromPathname(request)
	const userId = await getUserId(request)

	mixpanel.track(`PageView: ${languageId} - Home Landingpage`, {
		distinct_id: userId ?? 'anonymous',
		timeStamp: new Date().toISOString(),
	})

	return json({} as any)
}

export async function action({ request }: ActionFunctionArgs) {
	return json({} as any)
}

export const Chatbot = () => {
	const user = useOptionalUser()
	const languageId = useCurrentLanguage()

	const navigate = useCustomNavigate()
	const { t } = useTranslation(['home', 'common'])

	return (
		<div className="flex h-screen flex-col items-center justify-between">
			<MenuContainer showLogo={false} showFooter={false} showLanguageForm />
			<div className="flex flex-grow items-center justify-center">
				<div className="mt-14 flex flex-col items-center">
					<img
						alt="Wiser logo"
						src="/img/wiser-logo-small.png"
						className="-ml-5 h-auto w-48"
					/>
					<div className="bold my-4 flex  flex-col items-center">
						<p>{t('chatWithWiserBot')}</p>
						<p>{t('mentalChallenges')}</p>
					</div>
					<div className="col-span-1 my-5 flex flex-row gap-2 ">
						{user ? (
							<Button
								variant="default"
								onClick={() =>
									navigate({
										route: 'chat',
									})
								}
							>
								{t('goToChat')}
							</Button>
						) : (
							<>
								<Button
									variant="outline"
									onClick={() =>
										navigate({
											route: 'login',
											searchParams: `redirectTo=/${languageId}/chat`,
										})
									}
								>
									{t('auth.login', { ns: 'common' })}
								</Button>
								<Button
									variant="default"
									onClick={() =>
										navigate({
											route: 'signup',
											searchParams: `redirectTo=/${languageId}/intake`,
										})
									}
								>
									{t('auth.signUp', { ns: 'common' })}
								</Button>
							</>
						)}
					</div>
				</div>
			</div>
			<div className="w-full rounded-t-4xl md:rounded-t-5xl lg:rounded-t-7xl">
				<div className="mx-4 grid grid-cols-1 gap-4  lg:mx-10">
					<div className="col-span-1 mb-3 mt-5 min-h-96 md:mt-10">
						<Carousel
							plugins={[
								Autoplay({
									delay: 7_000,
								}),
							]}
							opts={{
								startIndex: 5,
								loop: true,
								containScroll: 'trimSnaps',
								align: 'center',
								watchSlides: false,
							}}
						>
							<CarouselContent className="-ml-5 min-h-[25rem]">
								{items.map((item, index) => (
									<CarouselItem
										key={index}
										className={`${
											index === 0
												? 'lg:lr-1 ml-[8px] mr-5 lg:mr-2'
												: '-ml-1 mr-2'
										} md:basis-1/3  md:pl-4 lg:basis-1/6 xl:basis-1/3`}
									>
										<div className={`flex items-center justify-center `}>
											<Card
												className="md:w-68 w-[22rem] rounded-2xl bg-secondary-400 p-10 md:mb-6"
												style={{
													backgroundImage: `url(${item.img})`,
													backgroundSize: 'cover',
													backgroundPosition: 'center',
													boxShadow:
														'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
												}}
											>
												<CardContent className="mt-4 flex h-64 w-64 items-start justify-start rounded-3xl ">
													{/* <span className="text-1xl  font-semibold ">
														{item.title}
													</span> */}
												</CardContent>
											</Card>
										</div>
									</CarouselItem>
								))}
							</CarouselContent>
						</Carousel>
					</div>
					<div className="mb-8 grid grid-flow-col items-center justify-center">
						<span
							className="text-bg-primary-400 mr-2 cursor-pointer underline"
							onClick={() => navigate({ route: 'termsOfService' })}
						>
							{t('terms.termsOfUse', { ns: 'common' })}
						</span>
						<span className="text-bg-primary-400 mx-2">|</span>
						<span
							className="text-bg-primary-400 ml-2 cursor-pointer underline"
							onClick={() => navigate({ route: 'privacy' })}
						>
							{t('terms.privacyPolicy', { ns: 'common' })}
						</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Chatbot
